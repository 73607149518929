<template>
<div class="contact"></div>
</template>

<script>
export default {
  name: 'Contact'
}
</script>

<style scoped>
  .contact{
  }
</style>
